/**
 * Simple underwater shader
 * 
 
 parameters:
 tDiffuse: texture
 time: this should increase with time passing
 distort_speed: how fast you want the distortion effect of water to proceed
 distortion: to what degree will the shader distort the screen 
 centerX: the distortion center X coord
 centerY: the distortion center Y coord

 explaination:
 the shader is quite simple
 it chooses a center and start from there make pixels around it to "swell" then "shrink" then "swell"...
 this is of course nothing really similar to underwater scene
 but you can combine several this shaders together to create the effect you need...
 And yes, this shader could be used for something other than underwater effect, for example, magnifier effect :)







 * @author vergil Wang
 */

import { Mesh, OrthographicCamera, PlaneBufferGeometry, Scene, ShaderMaterial, UniformsUtils, Vector2 } from 'three'
import { Pass } from 'three/examples/jsm/postprocessing/Pass'

var WaterShader = {
  uniforms: {
    byp: { value: 0 }, //apply the glitch ?
    tDiffuse: { type: 't', value: null },
    time: { type: 'f', value: 0.0 },
    factor: { type: 'f', value: 0.0 },
    resolution: { type: 'v2', value: null },
    distortion: { type: 'f', value: 0.4 },
    distortion2: { type: 'f', value: 0.2 },
    speed: { type: 'f', value: 0.5 },
    rollSpeed: { type: 'f', value: 0 },
  },

  vertexShader: `varying vec2 vUv;
    void main(){  
      vUv = uv; 
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,

  fragmentShader: 
  `uniform sampler2D tDiffuse;
  uniform float time;
  uniform float distortion;
  uniform float distortion2;
  uniform float speed;
  uniform float rollSpeed;
  varying vec2 vUv;


  vec3 mod289(vec3 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec2 mod289(vec2 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec3 permute(vec3 x) {
    return mod289(((x*34.0)+1.0)*x);
  }

  float snoise(vec2 v)
    {
    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0,
                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0),
                       -0.577350269189626,  // -1.0 + 2.0 * C.x,
                        0.024390243902439); // 1.0 / 41.0,
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);

    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
   x12.xy -= i1;

    i = mod289(i); // Avoid truncation effects in permutation,
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
  		+ i.x + vec3(0.0, i1.x, 1.0 ));

    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;

    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;

    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

    
    void main() {  
      vec2 p = vUv;
      float ty = time*speed;
      float yt = p.y - ty;
      //smooth distortion
      float offset = snoise(vec2(yt*3.0,0.0))*0.2;
      // boost distortion
      offset = offset*distortion * offset*distortion * offset;
      //add fine grain distortion
      offset += snoise(vec2(yt*50.0,0.0))*distortion2*0.001;
      //combine distortion on X with roll on Y
      gl_FragColor = texture2D(tDiffuse,  vec2(fract(p.x + offset),fract(p.y-time*rollSpeed) ));
  
    }`
}

/* 

void main() {
    vec2 p = vUv;
    float ty = time*speed;
    float yt = p.y - ty;
    //smooth distortion
    float offset = snoise(vec2(yt*3.0,0.0))*0.2;
    // boost distortion
    offset = offset*distortion * offset*distortion * offset;
    //add fine grain distortion
    offset += snoise(vec2(yt*50.0,0.0))*distortion2*0.001;
    //combine distortion on X with roll on Y
    gl_FragColor = texture2D(tDiffuse,  vec2(fract(p.x + offset),fract(p.y-time*rollSpeed) ));

  }`

  */

var BadTV = function (dt_size) {
  Pass.call(this)
  if (WaterShader === undefined) console.error('THREE.WaterPass relies on THREE.WaterShader')
  var shader = WaterShader
  this.uniforms = UniformsUtils.clone(shader.uniforms)
  if (dt_size === undefined) dt_size = 64
  this.uniforms['resolution'].value = new Vector2(dt_size, dt_size)
  this.material = new ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader
  })
  this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
  this.scene = new Scene()
  this.quad = new Mesh(new PlaneBufferGeometry(2, 2), null)
  this.quad.frustumCulled = false // Avoid getting clipped
  this.scene.add(this.quad)
  this.factor = 0
  this.time = 0
}

BadTV.prototype = Object.assign(Object.create(Pass.prototype), {
  constructor: BadTV,

  render: function (renderer, writeBuffer, readBuffer, deltaTime, maskActive) {
    const factor = Math.max(0, this.factor)
    this.uniforms['tDiffuse'].value = readBuffer.texture
    this.uniforms['time'].value = this.time
    /*
    
    uniform sampler2D tDiffuse; -check
  uniform float time; -check
  uniform float distortion;
  uniform float distortion2;
  uniform float speed;
  uniform float rollSpeed;

  */

    this.time += 0.05
    this.quad.material = this.material
    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      renderer.render(this.scene, this.camera)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      renderer.render(this.scene, this.camera)
    }
  }
})

export { BadTV }
