import React, { Suspense, useRef, useState, useEffect, useCallback } from "react"
import { Canvas, useFrame } from "react-three-fiber"
import { ContactShadows, Environment, useGLTF, useProgress } from "drei"
import { HexColorPicker } from "react-colorful"
import { proxy, useProxy } from "valtio"
import Text from './Text'
import Particles from './Particles'
import Effects from './Effects'



// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
const state = proxy({
  current: null,
  items: {
    laces: "#ffffff",
    mesh: "#ffffff",
    caps: "#ffffff",
    inner: "#ffffff",
    sole: "#ffffff",
    stripes: "#ffffff",
    band: "#ffffff",
    patch: "#ffffff",
  },
})


function timeout(ms) { //pass a time in milliseconds to this function
  return new Promise(resolve => setTimeout(resolve, ms));
}

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  console.log({progress});
  return <Effects/>
}

function House() {
  
  const ref = useRef()
  const blow = useRef()
  const bombw = useRef()
  const bombb = useRef()
  // Drei's useGLTF hook sets up draco automatically, that's how it differs from useLoader(GLTFLoader, url)
  // { nodes, materials } are extras that come from useLoader, these do not exist in threejs/GLTFLoader
  // nodes is a named collection of meshes, materials a named collection of materials
  const { nodes, materials } = useGLTF("model.glb")

  materials.BOMBAByC.emissiveIntensity = 20000;
  materials.SUELO.emissiveIntensity = 15;
  materials.EDIFICIOS.roughness = 0;
  materials.EDIFICIOS.metalness = 0.4;




  // Animate model
  useFrame((state) => {
    /*const t = state.clock.getElapsedTime()
    blow.current.scale.x = Math.abs(Math.sin(t-1)) * 2
    blow.current.scale.y = Math.abs(Math.sin(t-1)) * 2
    blow.current.scale.z = Math.abs(Math.sin(t-1)) * 2
    bombb.current.scale.x = Math.abs(Math.sin(t)) * 2
    bombb.current.scale.y = Math.abs(Math.sin(t)) * 2
    bombb.current.scale.z = Math.abs(Math.sin(t)) * 2
    bombw.current.scale.x = Math.abs(Math.sin(t)) * 2
    bombw.current.scale.y = Math.abs(Math.sin(t)) * 2
    bombw.current.scale.z = Math.abs(Math.sin(t)) * 2*/ 




ref.current.rotation.y = ref.current.rotation.y + 0.002;
if(Math.random() > 0.99)
materials.BOMBAByC.emissiveIntensity = 2 + Math.random()*3;

  })



  // Using the GLTFJSX output here to wire in app-state and hook up events
  return (
    <group
      ref={ref}
      dispose={null}
      scale={[0.2,0.2,0.2]}
      rotation={[0,0,0]}
      position={[0,-1.5,-17]}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group name="RootNode">
            <mesh material={materials.SUELO} geometry={nodes.SUELO1_SUELO_0.geometry} />
            <mesh material={materials.AGUA1} geometry={nodes.AGUA_AGUA1_0.geometry} />
            <mesh material={materials.ARBOLES1} geometry={nodes.ARBOLES_ARBOLES1_0.geometry} />
            <mesh material={materials.BASEyESCOMBROS} geometry={nodes.ESCOMBROS_BASEyESCOMBROS_0.geometry} />
            {/**BLOW */}
            <mesh ref={blow} material={materials.BOMBAByC} geometry={nodes.BOMBAC_BOMBAByC_0.geometry} />
            {/**BOMB WHITE */}
            <mesh ref={bombw} material={materials.BOMBAByC} geometry={nodes.BOMBAB_BOMBAByC_0.geometry} />
            {/**BOMB BLACK */}
            <mesh ref={bombb} material={materials.BOMBAA1} geometry={nodes.BOMBAA_BOMBAA1_0.geometry} />
            <mesh material={materials.CAMINOS1} geometry={nodes.CAMINOS_CAMINOS1_0.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0_1.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0_2.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0_3.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0_4.geometry} />
            <mesh material={materials.EDIFICIOS} geometry={nodes.EDIFICIOS1_EDIFICIOS_0_5.geometry} />
            <mesh material={materials.lambert8} geometry={nodes.polySurface6_lambert8_0.geometry} />
            <mesh material={materials.lambert9} geometry={nodes.polySurface7_lambert9_0.geometry} />
            <mesh material={materials.BASEyESCOMBROS} geometry={nodes.polySurface5_BASEyESCOMBROS_0.geometry} />
          </group>
        </group>
      </group>
    </group>
  )
}


export default function App() {

  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)



  return (
    <>
      <Canvas shadowMap       onMouseMove={onMouseMove}
      camera={{ position: [0, 2, 6], fov: 40, }}>
      <color attach="background" args={["black"]} />
        <spotLight intensity={0.15} angle={0.1} penumbra={1} position={[5, 25, 20]} />
        <Suspense fallback={<Loader />}>
          <House />
          <Particles count={ 5000 } mouse={mouse} />
<Effects/>
        </Suspense>
        <ambientLight intensity={0.1} />
      </Canvas>
    </>
  )
}
