/**
 * Simple underwater shader
 * 
 
 parameters:
 tDiffuse: texture
 time: this should increase with time passing
 distort_speed: how fast you want the distortion effect of water to proceed
 distortion: to what degree will the shader distort the screen 
 centerX: the distortion center X coord
 centerY: the distortion center Y coord

 explaination:
 the shader is quite simple
 it chooses a center and start from there make pixels around it to "swell" then "shrink" then "swell"...
 this is of course nothing really similar to underwater scene
 but you can combine several this shaders together to create the effect you need...
 And yes, this shader could be used for something other than underwater effect, for example, magnifier effect :)







 * @author vergil Wang
 */

import { Mesh, OrthographicCamera, PlaneBufferGeometry, Scene, ShaderMaterial, UniformsUtils, Vector2 } from 'three'
import { Pass } from 'three/examples/jsm/postprocessing/Pass'

var WaterShader = {

  uniforms: {
    byp: { value: 0 }, //apply the glitch ?
    tDiffuse: { type: 't', value: null },
    time: { type: 'f', value: 0.0 },
    factor: { type: 'f', value: 0.0 },
    resolution: { type: 'v2', value: null },
    distortion: { type: 'f', value: 1 },
    distortion2: { type: 'f', value: 1 },
    speed: { type: 'f', value: 0.5 },
    rollSpeed: { type: 'f', value: 0 },
    amount:   { type: "f", value: 0.002 },
		angle:    { type: "f", value: 0.0 }
  },

  vertexShader: 
  `varying vec2 vUv;
    void main(){  
      vUv = uv; 
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,

  fragmentShader: 
  `uniform int byp; //should we apply the glitch ?
    uniform float factor;
    uniform vec2 resolution;

    uniform sampler2D tDiffuse;
  uniform float time;
  uniform float distortion;
  uniform float distortion2;
  uniform float speed;
  uniform float rollSpeed;
  varying vec2 vUv;

  uniform float amount;
  uniform float angle;

   
    
    void main() {  


    vec2 offset = amount * vec2( cos(angle), sin(angle));
    vec4 cr = texture2D(tDiffuse, vUv + offset);
    vec4 cga = texture2D(tDiffuse, vUv);
    vec4 cb = texture2D(tDiffuse, vUv - offset);
    gl_FragColor = vec4(cr.r, cga.g, cb.b, cga.a);


  
    }`

    
}

/* 



  */

var RGBSplit = function (dt_size) {
  Pass.call(this)
  if (WaterShader === undefined) console.error('THREE.WaterPass relies on THREE.WaterShader')
  var shader = WaterShader
  this.uniforms = UniformsUtils.clone(shader.uniforms)
  if (dt_size === undefined) dt_size = 64
  this.uniforms['resolution'].value = new Vector2(dt_size, dt_size)
  this.material = new ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader
  })
  this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
  this.scene = new Scene()
  this.quad = new Mesh(new PlaneBufferGeometry(2, 2), null)
  this.quad.frustumCulled = false // Avoid getting clipped
  this.scene.add(this.quad)
  this.factor = 0
  this.time = 0
}

RGBSplit.prototype = Object.assign(Object.create(Pass.prototype), {
  constructor: RGBSplit,

  render: function (renderer, writeBuffer, readBuffer, deltaTime, maskActive) {
    const factor = Math.max(0, this.factor)
    this.uniforms['tDiffuse'].value = readBuffer.texture
    this.uniforms['time'].value = this.time
    /*
    
    uniform sampler2D tDiffuse; -check
  uniform float time; -check
  uniform float distortion;
  uniform float distortion2;
  uniform float speed;
  uniform float rollSpeed;

  */

    this.time += 0.05
    this.quad.material = this.material
    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      renderer.render(this.scene, this.camera)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      renderer.render(this.scene, this.camera)
    }
  }
})

export { RGBSplit }
